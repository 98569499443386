import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Headers from '../Components/Headerr/Headers'
import QRHeader from '../Components/QRManageement/QRHeader'
import Listing from '../Components/QRManageement/Listing'
import { useSelector } from 'react-redux'

function QRManagement() {
  const { data } = useSelector((state) => state.QRlisting);

  return (
    <Fragment>
      <div class="container-fluid main">
        <div class="row">
          <div className="col-sm-3 sideebarr">
            <Sidebar />
          </div>

          <div className="col-sm-9 sideebarr">
            <Headers />
            <QRHeader />
            <Listing data={data} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default QRManagement