import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Count } from '../../Redux/Actions/Count';
import { useNavigate } from "react-router-dom";

const DashboardData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = async () => {
    await navigate(`/dashboard/qr-management`);
  };

  const { data } = useSelector((state) => state.Dcount);
  useEffect(() => {
    dispatch(Count());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="container-fluid dasshboard">
        <div className="row">
          <div className="col-sm-12">
            <p>Welcome to the Dashboard</p>
          </div>
          <div className="col-sm-3 boxxx">
            <p className="nunmber">No of QR in System</p>
            <p className="bbold" onClick={() => navigate("/qr-management")}>
              {data?.NoOfQrInSystem}
            </p>
          </div>
          <div className="col-sm-3 boxxx">
            <p className="nunmber">No of QR Active</p>
            <p className="bbold" onClick={handleNavigate}>
              {data?.NoOfQrClaimed}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardData
