import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  generateQrFailure,
  generateQrStart,
  generateQrSuccess,
  getLastQrIdFailure,
  getLastQrIdStart,
  getLastQrIdSuccess,
  qrListFailure,
  qrListStart,
  qrListSuccess,
} from "../Reducers/qrListReducers";
import Cookies from "js-cookie";
import axios from "axios";
export const qrListing = createAsyncThunk(
  "auth",
  async ({ UserName, Status, IsActive, pageSize, page }, thunkAPI) => {
    try {
      thunkAPI.dispatch(qrListStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const queryParams = {
        ...(UserName && { Name: UserName }),
        ...(Status && { Status }),
        ...(IsActive && { IsActive }),
        ...(page && { page }),
        ...(pageSize && { size: pageSize }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/AdminQrListing`,
        {
          params: queryParams,
          headers: config.headers,
        }
      );
      const responseData = await response;

      thunkAPI.dispatch(qrListSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(qrListFailure(error.response.data.message));

      throw error;
    }
  }
);
export const generateQr = createAsyncThunk(
  "auth",
  async ({ formData }, thunkAPI) => {
    try {
      thunkAPI.dispatch(generateQrStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/CreateQrCode`,

        formData,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(generateQrSuccess(responseData));
      thunkAPI.dispatch(qrListing({}));
      thunkAPI.dispatch(getLastQrId());

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(generateQrFailure(error.response.data.message));

      throw error;
    }
  }
);

export const getLastQrId = createAsyncThunk("auth", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getLastQrIdStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/GetLastQrId`
    );
    thunkAPI.dispatch(getLastQrIdSuccess(response.data));
    return response;
  } catch (error) {
    thunkAPI.dispatch(getLastQrIdFailure(error.response.data.message));

    throw error;
  }
});