import { Button, Input, Select } from "antd";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import GenerateQR from "./GenerateQR";
import closeBtn from "../../Assets/cross.png";
import { debounce } from "lodash"; // Import debounce
import Loader from "../Loader";

const { Search } = Input;

const QRFilter = ({
  setUserName,
  setStatus,
  setIsActive,
  downloadImage,
  HandlePrintReadyPDF,
  loader,
  IsActive,
}) => {
  const [show, setShow] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("svg"); // Default to SVG format

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const debouncedSetUserName = useCallback(
    debounce((value) => {
      setUserName(value);
    }, 1000), // Debounce delay of 300ms
    []
  );

  const handleSearch = (e) => {
    debouncedSetUserName(e.target.value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleDownload = () => {
    if (downloadFormat === "svg") {
      downloadImage();
    } else if (downloadFormat === "pdf") {
      HandlePrintReadyPDF();
    }
  };

  const handleFormatChanges = (value) => {
    setDownloadFormat(value);
  };

  const handleActiveInactiveChange = (value) => {
    setIsActive(value);
  };

  // Cleanup the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedSetUserName.cancel();
    };
  }, [debouncedSetUserName]);

  return (
    <Fragment>
      <div className="container-fluid qrfilter">
        <div className="row">
          <div className="col-sm-2">
            <Input placeholder="Customer Name" onChange={handleSearch} />
          </div>
          <div className="col-sm-2">
            <Select placeholder="Sold/Unsold" onChange={handleStatusChange}>
              <Select.Option value={""}>All</Select.Option>
              <Select.Option value={"true"}>Sold</Select.Option>
              <Select.Option value={"false"}>Unsold</Select.Option>
            </Select>
          </div>
          <div className="col-sm-2">
            <Select
              placeholder="Active/Inactive"
              value={IsActive}
              onChange={handleActiveInactiveChange}
            >
              <Select.Option value={""}>All</Select.Option>
              <Select.Option value={"true"}>Active</Select.Option>
              <Select.Option value={"false"}>Inactive</Select.Option>
            </Select>
          </div>
          <div className="col-sm-2">
            <Select
              defaultValue="svg"
              value={downloadFormat}
              onChange={handleFormatChanges}
              placeholder="Download Format"
            >
              <Select.Option value="svg">Download As SVG</Select.Option>
              <Select.Option value="pdf">Download As PDF</Select.Option>
            </Select>
          </div>
          <div className="col-sm-2">
            <Button type="primary" onClick={handleDownload} disabled={loader}>
              {loader ? <Loader /> : "Download QR"}
            </Button>
          </div>
          <div className="col-sm-2">
            <Button type="primary" onClick={handleShow}>
              Generate QR
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="deleteModal generateQR"
      >
        <Modal.Header>
          <Button onClick={handleClose}>
            <img src={closeBtn} alt="closebtn" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <GenerateQR handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default QRFilter;
